import { IPosition } from './components/GetLocation'

import { Subject } from 'rxjs'
import { CreateAccountDataModel } from './apiTypes/IRISDataModel'
import { UpdateFinanceType, UpdateDocumentsType, Finance } from './apiTypes/Finance'

export interface IRequest {}

export interface IRequestGenerateUnlockCode extends IRequest {
  phoneNumber?: string;
  position: IPosition;
  plainMessage: boolean;
  email?: string;
}

export interface IRequestUpdateAccount extends IRequestCreateAccount {
}

/** Api to load existing account data */
export interface IRequestLoadExistingAccount extends IRequest {
  /** Existing subscriber ID to load data for */
  subscriberId: number;
  /** is this search unlocked to all subscribers */
  searchAllSubscribers?: boolean;
}

export interface IRequestUpdateAndResendAccountEmails extends IRequest {
  subscriberId: number;
  email: string;
}
export interface IRequestValidateUnlockCode extends IRequest {
  unlockCode: string;
  position: IPosition;
}

export interface IRequestViewUnlockCode extends IRequestValidateUnlockCode {
  phoneNumber: string;
}

export interface IRequestCreateAccount extends IRequest, CreateAccountDataModel {
}

export interface IRequestUpdateFinanceData extends UpdateFinanceType {
}

export interface IRequestStoreIncompleteFinance extends IRequest {
  subscriberId: number;
  position: IPosition;
  data: string;
}
export interface IRequestUpdateDocuments extends IRequest, UpdateDocumentsType {
}

export type DocumentDataWithoutSigners = {
  url: string;
  pdf: string;
  label: string;
  id: string;
};

export interface IRequestSendDocuments {
  subscriberId: number;
  template: 'SENDPRECONTRACTEMAILCATEGORY';
  documents: DocumentDataWithoutSigners[];
  email: string;
}
export interface IRequestGetCompletionCertificateData {

}
export interface IRequestCompleteFinanceDecline extends IRequestUpdateAccount {
  finance: UpdateFinanceType
}
export interface IRequestSendDocumentsToCustomer extends IRequestStoreIncompleteFinance {
  financeData: IRequestUpdateFinanceData
}

/**
 * Cancel account api basically same as update documents api
 */
export interface IRequestCancelIncomplete extends IRequestUpdateDocuments {}

export interface IResponseCreateAccount extends StatusIsCreate {
  accountCreationTime: string;
  subscriberId: number;
  parentUsername: string;
  parentPassword: string;
  parentFirstName: string;
  parentLastName: string;
  children: {
    firstName: string;
    lastName: string;
    loginName: string;
    password: string;
    userId: number;
  }[]
}

export interface IResponseCompletionCertificate extends StatusStatus {
  consultant: string;
  subscriberId: number;
  subscriberLoginName: string;
  subscriberPassword: string;
  currentTimestamp: string;
  customer: string;
  address: string;
  saleType: string;
  saleValue: number;
  deposit: number;
  accountCreatedOn: string;
  accountCreatedLocation: string;
  accountUnlockCode: string;
  unlockCodeRequestedLocation: string;
  unlockCodeGeneratedOn: string;
  unlockCodeSentTo: string;
  unlockCodeSentOn: string;
  unlockCodeViewedOn: string;
  unlockCodeViewedLocation: string;
  unlockCodeEnteredOn: string;
  unlockCodeEnteredLocation: string;
  welcomeEmailSentTo: string;
  welcomeEmailSentOn: string;
  numberOfStudents: number;
  numberOfModules: number;
  documentsEmailedTo: string;
  documentsEmailedOn: string;
  enrolmentDocuments: {
    documentId: string;
    documentTitle: string;
    documentUrl: string;
  }[];
  enrolmentSignatures: {
    documentId: string;
    documentTitle: string;
    signaturePoint: string;
    signedOn: string;
    signedLocation: string;
  }[]
  students: {
    firstName: string;
    lastName: string;
    loginName: string;
    password: string;
  }[],
  electronicRecordsSignaturesAgreedOn: string;
  electronicRecordsSignaturesAgreedLocation: string;
  loanAgreementAgreedOn: string;
  loanAgreementAgreedLocation: string;
  adequateExplanationAgreedOn: string;
  adequateExplanationAgreedLocation: string;
  preContractAgreedOn: string;
  preContractAgreedLocation: string;
  privacyPromptAgreedOn: string;
  privacyPromptAgreedLocation: string;
}

export type IResultStatus<K extends string> = {
  readonly [P in K]: boolean;
} & {
  readonly message: string;
}
export interface StatusIsValidate extends IResultStatus<'isValidate'> {
}
export interface StatusCodeSent extends IResultStatus<'codeSent'> {
}
export interface StatusStatus extends IResultStatus<'status'> {
}
export interface StatusUnlockAccount extends IResultStatus<'unlockAccount'> {
}
export interface StatusIsUpdate extends IResultStatus<'isUpdate'> {
}
export interface StatusIsCreate extends IResultStatus<'isCreate'> {
}

export interface AuthTokenResponse extends IResultStatus<'status'> {
  /** The number of seconds this token is valid for */
  expiresIn: number;
  /** type of token always Bearer */
  tokenType: 'Bearer';
  /** The JWT token */
  accessToken: string;
}

export interface IRequestLogRegHit {
  subscriberId?: number;
  documentId: string;
  title: string;
  action: 'view' | 'promptagree' | 'sign' | 'unsign' | 'agree' | 'unagree' | 'start' | 'end';
  actionPoint?: null | string;
  position: IPosition;
  time: string;
}

export interface IResponseExistingAccountInfo extends IResultStatus<'status'> {
  irisDataModel: Omit<CreateAccountDataModel, 'position'> & {
    /** registration code from server */
    regCode: string;
  }
}
export class InvalidPhoneNumberError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'InvalidPhoneNumberError'
  }
}

export interface NextSubidResponse {
  nextSubscriberID: number
  signedNextSubscriberID: string
}

export default abstract class Api {
  constructor (basePath: string, reghitId: number) {
    this._basePath = basePath
    this._reghitId = reghitId
    this.timeAdjustment = new Subject()
  }

  protected _basePath: string;
  protected _reghitId: number;
  public readonly timeAdjustment: Subject<number>;

  abstract logReghit(data: IRequestLogRegHit): Promise<StatusStatus>

  abstract generateUnlockCode (dataForGenerateUnlockCodeApi: IRequestGenerateUnlockCode): Promise<StatusCodeSent>

  abstract validateEmail (email: string, options: { instituteCode?: string }): Promise<StatusIsValidate>

  abstract validateUnlockCode (dataForUnlockApi: IRequestValidateUnlockCode): Promise<StatusUnlockAccount>

  abstract updateAccount (dataForUpdateAccountApi: IRequestUpdateAccount): Promise<StatusIsUpdate>

  abstract createAccount (dataForCreateApi: IRequestCreateAccount): Promise<IResponseCreateAccount>

  abstract loadExistingAccount (dataForExistingAccountApi: IRequestLoadExistingAccount): Promise<IResponseExistingAccountInfo>

  abstract updateFinanceData (dataForFinanceApi: IRequestUpdateFinanceData): Promise<StatusStatus>

  abstract getCompletionCertificateData (subscriberId: number): Promise<IResponseCompletionCertificate>

  abstract updateAndResendAccountEmails (dataForUpdatedAndResendAccountEmails: IRequestUpdateAndResendAccountEmails): Promise<StatusStatus>

  abstract storeIncompleteFinance (dataForStoreIncompleteFinanceApi: IRequestStoreIncompleteFinance): Promise<StatusStatus>

  abstract updateDocuments (dataForDocumentsApi: IRequestUpdateDocuments): Promise<StatusStatus>

  abstract sendDocuments (documentsData: IRequestSendDocuments): Promise<StatusStatus>

  abstract stopWatchDog (): void

  abstract logout (): Promise<void>

  abstract completeFinanceDecline (dataForCompleteFinanceDeclineApi: IRequestCompleteFinanceDecline): Promise<StatusIsUpdate>

  abstract sendDocumentsToCustomer (dataForSendDocumentsToCustomerApi: IRequestSendDocumentsToCustomer): Promise<StatusStatus>

  abstract updateViewUnlockCodeLocation (phoneNumberPositionAndUnlockCode: IRequestViewUnlockCode): Promise<StatusStatus>

  abstract cancelIncompleteAccount (dataForCancelIncompleteAccountApi: IRequestCancelIncomplete): Promise<StatusStatus>

  abstract getAuthToken (): Promise<AuthTokenResponse>

  abstract getEstiaLabsNextSubid (): Promise<NextSubidResponse>
}
